// ** Third Party Components
import styled from "styled-components";

// #####################################################

export const StyledIcon = styled.svg`
	vertical-align: -0.125em;
	height: 1em;
`;

// #####################################################
